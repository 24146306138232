<template>
    <div class="center">
        <van-search v-model="value" placeholder="搜索项目名称" shape="round" class="search" @input="onSearch" />
        <div class="projectList">
            <template v-for="(item,index) in list">
                <div class="projectOne" @click="goSub(item)" :key="index" v-if="!ifClosed">
                    <div class="content">
                        <div class="title">{{item.name}}</div>
                        <!-- <div class="message">
                            <span>已参与医生人数</span> <span>{{item.doctor_count}}</span>
                        </div>
                        <div class="message">
                            <span>上传病例数</span> <span>{{item.record_count}}</span>
                        </div>
                        <div class="message">
                            <span>审核通过病例数</span><span>{{item.record_pass_count}}</span>
                        </div> -->
                        <div class="message desc">
                            {{item.description}}
                        </div>
                    </div>
                    <div class="kv">
                        <img :src="item.kv" />
                    </div>
                </div>
            </template>
        </div>
        <Tabbar :name="'home'" :ifCanChange="ifCanChange"></Tabbar>
    </div>
</template>

<script>
    import {
        Dialog
    } from "vant";
    import {
        appHost
    } from '@/utils/host'
    import {parseUrl} from '@/utils/utils'
    import {
        getProject,
        getSubProject
    } from '@/api/api'
    import Tabbar from '@/components/tabbar.vue'
    import {
        mapActions,
        mapGetters,
        mapState
    } from 'vuex'
    export default {
        name: '',
        components: {
            Tabbar
        },
        data() {
            return {
                baseFileUrl: appHost,
                value: '',
                list: [],
                ifCanChange: true,
                audit_status: "",
                short_name: '',
            }
        },
        mounted() {
            this.SET_IDS({
                project_id: null,
                sub_id: null
            })
            this.init();
        },
        computed: {
            ...mapGetters(['isRealname']),
            ...mapGetters(['doctorIdsn']),
            ...mapState(['userId','doctorInfo','ifClosed','basicInformation']),
        },
        methods: {
            ...mapActions(['SET_IDS', 'SET_REG_TYPE','GET_IF_CLOSED']),
            async init(value) {
                this.SET_REG_TYPE(null) //清空
                this.GET_IF_CLOSED();//查询项目是否关闭
                this.list = await getProject({
                    params: {
                        name: value ? value : '',
                        enable: 1,
                        doctor_id: this.userId
                    }
                })
                // 获取资质认证状态
                this.short_name = this.basicInformation.projects[0].short_name
                await getSubProject({
                    params: {
                        project_id: this.basicInformation.projects[0].id,
                        doctor_id: this.basicInformation.id,
                        enable: 1
                    }
                }).then(res => {
                    console.log("资质认证状态",res[0].audit_status)
                    this.audit_status = res[0].audit_status
                })
            },
            onSearch() {
                this.init(this.value)
            },
            goSub(item) {
                // if (item.can_apply == 1) {
                if(this.audit_status=='资质认证成功' && this.short_name == 'bxzleq'){ 
                    console.log("靶向治疗二期资质认证成功直接跳转到病例中心页面",this.basicInformation)
                    this.SET_IDS({
                        project_id: this.basicInformation.projects[0]?.id,
                        sub_id: this.basicInformation.subprojects[0]?.id
                    })
                    this.$router.replace({  //靶向治疗二期资质认证成功直接跳转到病例中心页面
                        path: '/progress',
                    })
                }else{
                    if (this.isRealname==1) {
                        this.$router.replace({
                            path: '/sub_project',
                        })
                        this.SET_IDS({
                            project_id: item.id,
                            sub_id: null
                        })
                        this.SET_REG_TYPE(item.reg_type)
                    } else {
                        this.$router.replace({
                            path: '/ocr',
                        })
                    }
                }
                    
                // } else {
                //     Dialog.alert({
                //         message: '该医生不属于本项目服务范围，若有疑问请联系项目负责人员。',
                //         title: '非本项目服务范围',
                //         confirmButtonColor: '#02BB00',
                //         confirmButtonText: '我知道了'
                //     }).then(() => {

                //     })
                // }


            }
        }
    }
</script>

<style lang="scss" scoped>
    .center {
        .search {
            margin-bottom: 10px;
        }

        .projectList {
            padding: 0 12px 60px 12px;
            box-sizing: border-box;

            .projectOne {
                width: 100%;
                height: 120px;
                display: flex;
                padding: 0 8px 0 12px;
                box-sizing: border-box;
                background: #FFFFFF;
                // box-shadow: 0px 0px 13px 1px rgba(112, 147, 242, 0.36);
                box-shadow: 0px 1px 7px 0px rgba(22, 23, 24, 0.08);
                border-radius: 7px;
                margin-bottom: 14px;

                .kv {
                    width: 50%;
                    display: flex;
                    align-items: center;

                    img {
                        max-height: 110px;
                        width: 100%;
                        // box-shadow: 0px 0px 13px 1px rgba(112, 147, 242, 0.36);
                        border-radius: 4px;
                    }
                }

                .content {
                    flex: 1;
                    padding-top: 14px;
                    padding-right: 40px;
                    box-sizing: border-box;
                    position: relative;

                    .title {
                        height: 40px;
                        font-family: PingFangSC-Medium;
                        width: 100%;
                        font-size: 15px;
                        color: #232323;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                    }

                    .message {
                        font-size: 13px;
                        color: #909090;
                        margin-bottom: 4px;
                        display: flex;
                        justify-content: space-between;

                        &.desc {
                            text-align: justify; text-align-last: left; line-height: 1.4;
                            overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; word-break: break-word;
                        }
                    }
                }
            }

        }
    }
</style>